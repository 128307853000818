<template>
  <div class="page">
    <Navbar title="微销摊位" type="SMP" />
    <van-row class="total" :gutter="5">
      <van-col :span="8">
        <div class="item fc-cyan">
          <div class="amount">{{ count.site }}个</div>
          <div class="title">摊位数量</div>
        </div>
      </van-col>
      <van-col :span="8">
        <div class="item fc-orange">
          <div class="amount">{{ count.order }}个</div>
          <div class="title">订单数量</div>
        </div>
      </van-col>
      <van-col :span="8">
        <div class="item fc-blue">
          <div class="amount">{{ count.profit }}元</div>
          <div class="title">摊位收益</div>
        </div>
      </van-col>
    </van-row>
    <div class="site">
      <van-row class="title">
        <van-col :span="18" class="name"
          ><van-icon name="notes-o" /> 摊位列表</van-col
        >
        <van-col :span="6">
          <div class="btn fc-cyan" @click="hireSite()">
            <van-icon name="cart-o" /> 租赁摊位
          </div></van-col
        >
      </van-row>
      <van-row class="item" v-for="site in sites" :key="site.code">
        <van-col :span="18">
          <div class="name">
            <van-icon name="shop-o" /> {{ site.name }}({{ site.code }})
          </div>
          <div class="address">
            <van-icon name="location-o" /> {{ site.address }}
          </div>
          <div class="time fc-orange">
            <van-icon name="flag-o" /> 2022-01-11日到期
          </div>
        </van-col>
        <van-col :span="6" class="order fc-cyan"
          >共{{ site.orderCount }}个订单</van-col
        >
      </van-row>
      <van-row v-if="sites.length <= 0">
        <van-col :span="24">
          <div>
            <van-image
              width="103"
              height="103"
              :src="require('../../../assets/images/home/no-record.png')"
            ></van-image>
          </div>
        </van-col>
      </van-row>
    </div>
    <Tabbar active="site" />
    <Share ref="share" />
  </div>
</template>
<script>
import Navbar from '../../module/common/Navbar.vue'
import Tabbar from '../common/Tabbar.vue'
import Share from '../../module/common/Share.vue'
import { Image, Icon, Tag } from 'vant'
export default {
  components: {
    Navbar: Navbar,
    Tabbar: Tabbar,
    Share: Share,
    [Image.name]: Image,
    [Icon.name]: Icon,
    [Tag.name]: Tag
  },
  data () {
    return {
      sites: [],
      count: { site: 0, order: 0, profit: 0 }
    }
  },
  mounted () {
    this.retrieveSiteList()
    this.$refs.share.default('seller')
  },
  methods: {
    hireSite () {
      this.$router.push({ path: '/seller/siteHire' })
    },
    async retrieveSiteList () {
      var seller = window.sessionStorage.getItem(this.SESSION_SELLER)
      var pd = { sellerCode: seller }
      const { data: res } = await this.$http.post(this.BMS_URL + '/media/site/retrieveSellerSiteList', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.sites = res.data
        this.count.site = this.sites.length
        this.sites.forEach(element => {
          this.count.order += element.orderCount
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.total {
  height: 75px;
  width: 98%;
  margin: 5px auto 0px auto;
  .item {
    background-color: #f7f8fa;
    padding: 10px 0px;
    width: 100%;
    margin: 0px auto;
    border-radius: 5px;
    .title {
      font-size: 13px;
      height: 20px;
      line-height: 20px;
    }
    .amount {
      font-size: 15px;
      font-weight: 600;
      height: 20px;
      line-height: 20px;
    }
    .increase {
      font-size: 13x;
      height: 20px;
      line-height: 20px;
    }
  }
}
.site {
  .title {
    height: 30px;
    line-height: 30px;
    text-align: left;
    padding-left: 20px;
    border-bottom: 1px solid #eee;
    .name {
      font-size: 14px;
      font-weight: 600;
    }
    .btn {
      border: 1px solid #eee;
      border-radius: 5px;
      height: 18px;
      font-size: 12px;
      width: 65px;
      line-height: 20px;
      padding: 2px 6px;
    }
  }
  .item {
    text-align: left;
    padding: 10px 0px 10px 10px;
    border-bottom: 1px solid #eee;
    .name {
      height: 20px;
      line-height: 20px;
      font-weight: 15px;
      font-weight: 600;
    }
    .address {
      height: 20px;
      line-height: 20px;
    }
    .time {
      height: 20px;
      line-height: 20px;
    }
    .order {
      height: 60px;
      line-height: 60px;
    }
  }
}
</style>
